// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: '1efff746-fb6f-44a0-b9cd-6dfc8b8bc4f7',
    authority:
      'https://login.microsoftonline.com/44f8af53-dabe-461c-807f-725307b9060d',
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: [
    'User.Read',
    'User.Read.All',
    'Files.Read',
    'Files.ReadWrite',
    'Files.Read.All',
    'Files.ReadWrite.All',
    'Sites.Read.All',
    'Sites.ReadWrite.All',
    'People.Read',
    'People.Read.All',
    'Calendars.Read',
    'Calendars.ReadWrite',
    'Calendars.Read.Shared',
    'MailboxSettings.ReadWrite',
  ],
}

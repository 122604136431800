import {
  InteractionRequiredAuthError,
  InteractionStatus,
  InteractionType,
} from '@azure/msal-browser'
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from '@azure/msal-react'
import { Router } from '@reach/router'
import React, { useEffect, useState } from 'react'
import ErrorComponent from '../components/ErrorComponent'
import Layout from '../components/Layout'
import Loading from '../components/Loading'
import { loginRequest } from '../utils/authConfig'

export const ChatPage = () => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [accessToken, setAccessToken] = useState(null)
  const [idToken, setIdToken] = useState(null)

  useEffect(() => {
    if (
      account &&
      !accessToken &&
      !idToken &&
      inProgress === InteractionStatus.None
    ) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account,
        })
        .then(response => {
          console.log({ ...response })
          setAccessToken(response.accessToken)
          setIdToken(response.idToken)
        })
        .catch(async error => {
          if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return instance.acquireTokenPopup({ ...loginRequest, account })
          }
        })
    }
  }, [account, inProgress, instance, accessToken])

  const authRequest = {
    ...loginRequest,
  }

  const onChatClick = () => {
    // window.Webchat.open()
  }

  return (
    <Layout
      seo={{ pageTitle: `Employee Chat` }}
      lightText
      header={{
        title: 'Employee Chat',
        subtitle: 'Chat with the DFS assistant as an employee',
      }}
    >
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={ErrorComponent}
        loadingComponent={Loading}
      >
        {/* <Webchat
          applicationId="60628d31c76956d975a964f5"
          settings={{
            serviceUrl: 'http://localhost:80',
            logoUrl:
              'https://dfs-cms-uploads.s3.amazonaws.com/logo_7dd18f0d8c.png',
            inputTextFieldHint: 'Type a message...',
            appearance: {
              primaryColor: '#b11a1b',
              textColorPrimary: '#FFF',
              secondaryColor: '#FFD9D9',
              textColorSecondary: '#000',
            },
            initialContext: {
              accessToken,
              idToken,
            },
            reCaptchaKey: '6Le2hooaAAAAAPHXLruM-374HbYVaIGEnF1VSCzn',
          }}
        /> */}
        <section className="section with-label">
          <div className="container is-max-desktop content">
            <h2 className="font-semibold has-text-align-center">
              This page is a WIP
            </h2>
          </div>
        </section>
      </MsalAuthenticationTemplate>
    </Layout>
  )
}

const ChatApp = () => (
  <Router>
    <ChatPage path="/chat" />
  </Router>
)

export default ChatApp
